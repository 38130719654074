
import { 
  IonPage, 
  IonHeader, 
  IonToolbar, 
  IonTitle, 
  IonContent,
  IonButtons,
  IonBackButton,
  IonIcon,
  IonList,
  IonItem,
  IonLabel,
  onIonViewDidEnter,
 } from '@ionic/vue';

import { 
  chevronBack, 
  logOutOutline, 
  personCircleOutline, 
  personAddOutline,
  personOutline,
  lockClosedOutline,
  documentsOutline,
  helpCircleOutline,
  mail,

  } from 'ionicons/icons';

import { defineComponent, ref } from '@vue/runtime-core';
import { useRouter } from 'vue-router';
import { useStore } from '@/store';

export default  defineComponent({
  name: 'Settings',
  components: { 
    IonPage, 
    IonHeader, 
    IonToolbar, 
    IonTitle, 
    IonContent,
    IonButtons,
    IonBackButton,
    IonIcon,
    IonList,
    IonItem,
    IonLabel,
  },
  setup(){

    const router = useRouter();
    const store = useStore();

    const showSupportEmail = ref(false);
    onIonViewDidEnter(()=>{
      showSupportEmail.value = false;
    });

    async function onLogOut(){
      await store.dispatch("logOut");

      /* 
      Routing with ion-tabs and Vue appears to be a total 
      mess.  We need to clear all route stacks on logout and
      it seems the only way to do this is to simply refresh
      the page.  Because the credentials have been removed
      from local storage the user will be redirected to 
      the login page.
      */
      
      window.location.reload();

    }
    return {
      chevronBack,
      logOutOutline,
      personCircleOutline,
      personAddOutline,
      personOutline,
      lockClosedOutline,
      documentsOutline,
      helpCircleOutline,
      mail,

      showSupportEmail,

      onLogOut,
      router,
      store,
    }
  }
});
